import React, { useCallback, useMemo, useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import styled from 'styled-components';
import { Feature, FeatureCollection } from 'geojson';
import { Insights } from '../../utils/interfaces';
import constants from '../constants';
import { Layer, MapRef, Map, Source, MapLayerMouseEvent, ScaleControl, NavigationControl } from 'react-map-gl';

const MapContainer = styled.div<{ isLoadingData: Boolean }>`
    width: 100%;
    height: 100%;
    opacity: ${(props) => (props.isLoadingData ? 0.5 : 1)};
`;

export interface ILayer {
    id: string;
    name: string;
    static?: boolean;
    data: FeatureCollection | undefined;
    fill?: any;
    stroke?: string;
    filter?: any[];
    fillHover?: 'string';
    strokeHover?: 'string';
    type: 'line' | 'circle' | 'fill';
    fillOpacity?: number;
    paint?: any;
    popupType?: 'detection' | 'toi';
    circleRadius?: number;
    cluster?: boolean;
}

const RMap: React.FC<{
    insights: Insights | undefined;
    clouds: any | undefined;
    customData: any | undefined;
    metaData: any | undefined;
    activeDate: Date | undefined;
    availableDates: Date[] | undefined;
    isInsightsLoading: Boolean;
    map: any;
    setMap: (map: any) => void;
    popupRef: React.MutableRefObject<mapboxgl.Popup>;
    visibleLayers: string[];
    selectedFeature: Feature | null;
    setSelectedFeature: (feature: Feature | null) => void;
    setHoveredFeature: (feature: Feature | null) => void;
    hoveredFeature: Feature | null;
    detectionConfidence: number;
}> = ({
    insights,
    clouds,
    customData,
    isInsightsLoading,
    setMap,
    selectedFeature,
    setSelectedFeature,
    visibleLayers,
    setHoveredFeature,
    hoveredFeature,
    metaData,
    detectionConfidence,
}) => {
    let hoveredItem = useRef<{ layer_id: string; feature_id: string | number | undefined } | null>(null);
    const viewport = {
        latitude: 57.33870347771735,
        longitude: 2.042840975438542,
        zoom: 5,
    };

    const handleMapClick = (event: MapLayerMouseEvent) => {
        if (event.features && event.features.length > 0) {
            const clickedFeature = event.features[0];
            const source = clickedFeature.source;
            const insightFeature =
                insights && source in insights
                    ? (insights[source as keyof Insights] as FeatureCollection)?.features?.find(
                          (feature: Feature) => feature.properties?.uuid === clickedFeature.properties?.uuid
                      )
                    : undefined;
            if (insightFeature) {
                setSelectedFeature(insightFeature);
            } else {
                setSelectedFeature(null);
            }
        }
    };

    const onHover = useCallback(
        (event: MapLayerMouseEvent) => {
            if (event.features && event.features.length === 0 && hoveredItem.current !== null) {
                mapRef.current?.setFeatureState(
                    { source: hoveredItem.current.layer_id, id: hoveredItem.current.feature_id },
                    { hover: false }
                );
                hoveredItem.current = null;
                setHoveredFeature(null);
                return;
            }

            if (event.features && event.features.length > 0) {
                if (hoveredItem.current !== null) {
                    mapRef.current?.setFeatureState(
                        { source: hoveredItem.current.layer_id, id: hoveredItem.current.feature_id },
                        { hover: false }
                    );
                }

                hoveredItem.current = {
                    layer_id: event.features[0].layer.id,
                    feature_id: event.features[0].id,
                };
                setHoveredFeature(event.features[0]);
                mapRef.current?.setFeatureState(
                    { source: event.features[0].layer.id, id: event.features[0].id },
                    { hover: true }
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const mapRef = React.useRef<MapRef | null>(null);

    const filter: [string, (string | string[])[], number] = useMemo(() => {
        return ['>=', ['to-number', ['get', 'object_class_score']], detectionConfidence / 100];
    }, [detectionConfidence]);

    useEffect(() => {
        if (mapRef.current && metaData) {
            mapRef.current?.flyTo({ center: metaData.metadata.center_pos, duration: 5000 });
        }
    }, [mapRef, metaData]);
    const layers = useMemo(() => {
        if (!insights) return [];
        let colors = constants.colors.markers;
        let new_layers: ILayer[] = [
            {
                id: 'aoi',
                name: 'aoi',
                type: 'line',
                paint: {
                    'line-color': '#00A1FF',
                },
                data: metaData?.features
                    ? ({ type: 'FeatureCollection', features: metaData.features } as FeatureCollection)
                    : { type: 'FeatureCollection', features: [] }, // Safe fallback
            },
            {
                id: `custom_data`,
                name: 'custom_data',
                data: customData!,
                paint: { 'fill-color': constants.colors.pallet.lightGreen, 'fill-opacity': 0.3 },
                type: 'fill',
            },
            {
                id: `footprints`,
                name: 'footprints',
                static: true,
                data: insights!.product_footprints,
                paint: {
                    'fill-color': [
                        'match',
                        ['get', 'processing_status'],
                        'completed',
                        '#28A745',
                        'started',
                        '#00A1FF',
                        'waiting',
                        '#FFD700',
                        '#00A1FF',
                    ],
                    'fill-opacity': 0.3,
                },
                type: 'fill',
            },
            {
                id: `cloudmasks`,
                name: 'cloudmasks',
                static: true,
                data: clouds!,
                paint: { 'fill-color': '#c0e0f3', 'fill-opacity': 0.2 },
                type: 'fill',
            },
            {
                id: `static_objects`,
                name: 'static_objects',
                static: true,
                data: insights!.static_objects,
                paint: {
                    'circle-color': '#000',
                    'circle-stroke-color': '#00A1FF',
                    'circle-stroke-width': 1,
                },
                type: 'circle',
                popupType: 'detection',
            },
            {
                id: `undetected_ais`,
                name: 'undetected_ais',
                data: insights!.undetected_ais,
                paint: {
                    'circle-color': colors.undetectedAis.fill,
                    'circle-stroke-color': colors.undetectedAis.stroke,
                    'circle-stroke-width': 1,
                },
                type: 'circle',
                popupType: 'detection',
                circleRadius: 3,
            },
            {
                id: `dark_vessels`,
                name: 'dark_vessels',
                data: insights!.dark_vessels,
                paint: {
                    'circle-color': colors.darkShips.fill,
                    'circle-stroke-color': colors.darkShips.stroke,
                    'circle-stroke-width': 1,
                },
                type: 'circle',
                popupType: 'detection',
            },
            {
                id: `matched_vessels`,
                name: 'matched_vessels',
                data: insights!.matched_vessels,
                paint: {
                    'circle-color': colors.matchedVessels.fill,
                    'circle-stroke-color': colors.matchedVessels.stroke,
                    'circle-stroke-width': 1,
                },
                type: 'circle',
                popupType: 'detection',
            },
        ];
        return new_layers;
    }, [insights, clouds, metaData, customData]);

    // Filter layers based on visibleLayers prop
    const filteredLayers = useMemo(() => {
        return layers.filter((layer) => visibleLayers.includes(layer.id));
    }, [layers, visibleLayers]);

    const onMapLoad = useCallback(() => {
        if (!mapRef.current) return;
        setMap(mapRef.current);
    }, [setMap]);

    return (
        <MapContainer isLoadingData={isInsightsLoading}>
            <Map
                initialViewState={viewport}
                ref={mapRef}
                onLoad={onMapLoad}
                mapStyle="mapbox://styles/vake-torstein/cl4xzn1gs000p14qgz8nqs0ms"
                mapboxAccessToken="pk.eyJ1IjoidmFrZS10b3JzdGVpbiIsImEiOiJjbDR4emJmbW0xdTRpM21tbG1lbnc3b2JrIn0.V7PXI4I2ndH2mxaJrVCnRw"
                interactiveLayerIds={['static_objects', 'undetected_ais', 'dark_vessels', 'matched_vessels'].filter(
                    (id) => visibleLayers.includes(id)
                )} // Intersection of existing interactiveLayerIds and filteredLayers
                onClick={handleMapClick}
                onMouseMove={onHover}
            >
                <ScaleControl />
                <NavigationControl position="bottom-right" />
                {filteredLayers.map((layer) => (
                    <Source key={layer.id} id={layer.id} type="geojson" data={layer.data}>
                        {layer.id === 'dark_vessels' ? <Layer {...layer} filter={filter} /> : <Layer {...layer} />}
                    </Source>
                ))}
                {selectedFeature?.properties && (
                    <Source key="selectedFeature" id="selectedFeature" type="geojson" data={selectedFeature}>
                        <Layer
                            id="selectedFeature"
                            type="circle"
                            paint={{
                                'circle-radius': 12,
                                'circle-stroke-color': '#f0d971',
                                'circle-stroke-width': 2,
                                'circle-opacity': 0.2,
                                'circle-stroke-opacity': 0.4,
                                'circle-color': '#f0d971',
                            }}
                        />
                    </Source>
                )}
                {hoveredFeature && hoveredFeature.properties && (
                    <Source key="hoveredFeature" id="hoveredFeature" type="geojson" data={hoveredFeature}>
                        <Layer
                            id="hoveredFeature"
                            type="circle"
                            paint={{
                                'circle-radius': 12,
                                'circle-stroke-color': '#f0d971',
                                'circle-stroke-width': 2,
                                'circle-opacity': 0.2,
                                'circle-stroke-opacity': 0.4,
                                'circle-color': '#f0d971',
                            }}
                        />
                    </Source>
                )}
            </Map>
        </MapContainer>
    );
};

export default RMap;
